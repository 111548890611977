<template>
  <main-layout>
    <div class="content-container">
      <div id="application-view" class="content-box">
        <div class="row">
          <div class="col-md-8 separator-bottom">
            <h1 class="title-md">Aplicação</h1>
          </div>
          <div class="col-md-4 last">
            <button class="app-btn app-btn-cancel" @click="deleteApplicationMixin($route.params.id)"
              v-if="this.$route.fullPath.indexOf('/applications/new') == -1 && isAdministrator()">Remover</button>
            <button class="app-btn app-btn-outline left-space" @click="goBackWindow()"
              :disabled="$route.params.id != 'new' && application.idapp == undefined">Voltar</button>
            <button class="app-btn app-btn-outline left-space"
              @click="saveApplicationMixin(application, $route.params.id)"
              v-if="this.$route.fullPath.indexOf('/applications/new') == -1 && isAdministrator() && tab == 'base'">Salvar</button>
            <button class="app-btn app-btn-outline left-space"
              @click="saveApplicationMixin(application, $route.params.id)"
              v-if="this.$route.fullPath.indexOf('/applications/new') != -1 && isAdministrator()">Registrar</button>
          </div>
          <div class="col-md-12">
            <div class="nav-tab">
              <button class="app-btn left-btn" style="margin-left: 0px;" @click="changeTab('base')"
                v-bind:class="{ 'app-btn-primary ': tab == 'base', 'app-btn-outline ': tab != 'base' }">Dados da
                aplicação</button>
              <button class="app-btn right-btn" @click="changeTab('companies')"
                v-bind:class="{ 'app-btn-primary ': tab == 'companies', 'app-btn-outline ': tab != 'companies' }"
                v-if="this.$route.fullPath.indexOf('applications/new') == -1">Locatários associados</button>
            </div>
          </div>
        </div>
        <ValidationObserver ref="validator" class="row" v-if="tab == 'base'">
          <!-- <div class="col-md-3">
            <div class="row">
              <div class="col-md-12">
                <div class="no-image"></div>
              </div>
            </div>
          </div> -->
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <ok-input :model="application.name" @updated="
                  (x) => {
                    application.name = x;
                  }
                " :label="'Nome'" :placeholder="' '" :id="'name'" :name="'name'" rules="required">
                </ok-input>
              </div>
              <div class="col-md-6">
                <ok-input :model="application.trusted_sources" @updated="
                  (x) => {
                    application.trusted_sources = x;
                  }
                " :label="'Fontes confiáveis'" :placeholder="' '" :id="'trusted_sources'" :name="'trusted_sources'"
                  rules="required">
                </ok-input>
              </div>
              <div class="col-md-6">
                <ok-input :model="application.app_key" @updated="
                  (x) => {
                    application.app_key = x;
                  }
                " :label="'Chave da aplicação'" :placeholder="' '" :id="'app_key'" :name="'app_key'">
                </ok-input>
              </div>
              <div class="col-md-6">
                <ok-input :model="application.secret_key" @updated="
                  (x) => {
                    application.secret_key = x;
                  }
                " :label="'Chave secreta'" :placeholder="' '" :id="'secret_key'" :name="'secret_key'">
                </ok-input>
              </div>
              <div class="col-md-4">
                <div class="toggle-item">
                  <p>Status</p>
                  <toggle-button class="toggle-btn" :value="false" v-model="application.status" :sync="true" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 separator-bottom">
            <hr class="separator">
            <h2 class="title-sm">Webhooks</h2>
          </div>
          <div class="col-md-12">
            <ok-input :model="application.registration_url" @updated="
              (x) => {
                application.registration_url = x;
              }
            " :label="'Url de cadastro'" :placeholder="' '" :id="'registration_url'" :name="'registration_url'">
            </ok-input>
          </div>
          <div class="col-md-12">
            <ok-input :model="application.request_url" @updated="
              (x) => {
                application.request_url = x;
              }
            " :label="'Url de solicitação'" :placeholder="' '" :id="'request_url'" :name="'request_url'">
            </ok-input>
          </div>
          <div class="col-md-12">
            <ok-input :model="application.entry_url" @updated="
              (x) => {
                application.entry_url = x;
              }
            " :label="'Url de acesso'" :placeholder="' '" :id="'entry_url'" :name="'entry_url'">
            </ok-input>
          </div>
        </ValidationObserver>
        <div class="row fix-right-margin" v-if="tab == 'companies'">
          <div class="col-md-12 row" v-if="tab2 == 'list'">
            <!-- <div class="col-md-8" style="padding-bottom: 20px">
              <h3 style="line-height: 36px;">Parques associados</h3>
            </div> -->
            <div class="col-md-12 last" style="padding-bottom: 20px">
              <button class="app-btn app-btn-secondary" @click="tab2 = 'select'" v-if="isSecurityAdm()">Associar
                locatário</button>
            </div>
            <div class="col-md-12" v-for="company in application.companies" :key="'r' + company.idcompany">
              <company-item :companyItem="company" :showRemove="isGlpMember()" @done="loadApplication"></company-item>
            </div>
            <div class="col-md-12">
              <div class="no-content" v-if="application.companies && application.companies.length == 0">
                Nenhum locatário associado a aplicação
              </div>
            </div>
          </div>

          <div class="col-md-12 row" v-if="tab2 == 'select'">
            <!-- <div class="col-md-8" style="padding-bottom: 20px">
              <h3 style="line-height: 36px;">Parques associados</h3>
            </div> -->
            <div class="col-md-12 last" style="padding-bottom: 20px">
              <button class="app-btn app-btn-outline" @click="tab2 = 'list'" v-if="isSecurityAdm()">Listar
                locatários</button>
            </div>
            <div class="col-md-9" style="width: calc(100% - 175px);">
              <ok-input :model="filter.searchText" @updated="
                (x) => {
                  filter.searchText = x;
                }
              " :label="'Pesquisar'" :placeholder="' '" :id="'searchText'" :name="'searchText'"
                @onEnter="listCompanies()">
              </ok-input>
            </div>
            <div class="col-md-3" style="width: 175px;">
              <button class="app-btn app-btn-text" style="margin-top: 9px" @click="cleanFilter()">Limpar
                resultados</button>
            </div>
            <div class="col-md-12" v-for="company in companiesListFiltered(application.companies)"
              :key="company.idcompany">
              <company-item :companyItem="company" :showAdd="isGlpMember()" @done="loadApplication"></company-item>
            </div>
            <div class="col-md-12">
              <div class="no-content" v-if="companyList && companyList.length == 0">
                {{ listCompanyText }}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import applicationMixin from "../mixins/application.mixin.js";
import companyMixin from "../mixins/company.mixin.js";
import MainLayout from "../layouts/Template.vue";
import CompanyItem from '@/components/CompanyItem.vue'
import OkInput from '@/components/OkInput.vue'

export default {
  mixins: [authMixin, applicationMixin, companyMixin],
  components: {
    MainLayout,
    OkInput,
    CompanyItem,
  },
  data() {
    return {
      tab: "base",
      tab2: "list",
      listCompanyText: "Pesquise o nome do locatário desejado",
      companyList: [],
      filter: {
        searchText: null,
      },
      lastCompanyList: null
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id !== 'new') {
      this.loadApplication();
      // this.listCompanies();
    }


  },
  methods: {
    goBackWindow() {
      this.goBack(window)
    },
    loadApplication() {
      let self = this;
      const id = this.$route.params.id;
      this.getApplicationMixin(id).then((response) => {
        self.application = response.application;
      });
    },
    listCompanies() {
      let self = this;
      const currentDate = new Date
      if (!self.lastCompanyList || currentDate.getTime() - self.lastCompanyList.getTime() >= 3000) self.lastCompanyList = new Date
      self.listCompanyText = 'Buscando locatários...'
      this.listCompaniesMixin(500, null, this.filter.searchText).then((response) => {
        self.companyList = response.companyList;
        self.listCompanyText = 'Nenhum locatário encontrado'
      });
    },
    changeTab(value) {
      if (value != 'companies') {
        this.tab2 = 'list'
      }
      this.tab = value;
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#application-view {
  .no-image {
    width: 100%;
    padding-top: 100% !important;
    background-color: $gray-md;
  }

}
</style>