<template>
  <div class="data-item registration-item">
    <div class="row" v-if="!simple">
      <div class="col-md-8 data-middle">
        <ImageItem :path="registrationItem.Driver.image_photo" :isSmall="false"></ImageItem>
        <div class="middle-infos">
          <p class="highlight">Nome: {{ registrationItem.Driver.name }} {{ registrationItem.Driver.lastname }}</p>
          <p>Empresa do motorista: {{ registrationItem.Driver.company_name | verifyIfNull }}</p>
          <p>Telefone: {{ registrationItem.Driver.phone | formatPhone | verifyIfNull }}</p>
          <p>Email: {{ registrationItem.Driver.email | verifyIfNull }}</p>
        </div>
      </div>
      <div class="col-md-4 data-right">
        <p class="highlight">{{ registrationItem.Company.name }}</p>
        <p>Data da solicitação: {{ registrationItem.created_at | formatDate | verifyIfNull }}</p>
        <p>Data de expiração: {{ registrationItem.expiration | formatDate | verifyIfNull }}</p>
        <p>Status: <span class="highlight" v-bind:class="userStatusClass(registrationItem.status)">{{
          requestStatus(registrationItem.status) }}</span>
        </p>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-md-8 data-left">
        <p style="margin-left: 0px">Data do registro: {{ registrationItem.created_at | formatDate | verifyIfNull }}</p>
        <p style="margin-left: 0px">Data de expiração: {{ registrationItem.expiration | formatDate | verifyIfNull }}</p>
      </div>
      <div class="col-md-4 data-right">
        <p>Status: <span class="highlight" v-bind:class="userStatusClass(registrationItem.status)">{{
          requestStatus(registrationItem.status) }}</span>
        </p>
      </div>
    </div>
    <overlay-item :route="'/registrations/' + registrationItem.idregistration"></overlay-item>

  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import userMixin from "../mixins/user.mixin.js";
import OverlayItem from '@/components/OverlayOpenNewTab.vue'
import ImageItem from '@/components/ImageItem.vue'

export default {
  mixins: [userMixin],
  props: ["registrationItem", 'simple'],
  components: {
    OverlayItem,
    ImageItem,
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  watch: {},
  methods: {
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.registration-item {}

@media only screen and (max-width: 600px) {
  .registration-item {}
}
</style>