import axios from 'axios';
import { server } from '../js/variables.js'
// import moment from 'moment'

var driverMixin = {
  data: function () {
    return {
      driver: {
        status: false,
        street: null,
        state: null,
        city: null,
        district: null,
      },
    }
  },
  methods: {
    listDriversMixin(limit, page, search, order, sort, status, bindStatus, idcompany) {
      const self = this;
      let config = JSON.parse(JSON.stringify(server.config));
      config["params"] = {
        limit: limit,
        page: page,
        search: search,
        order: order,
        sort: sort,
        status: status,
        third_driver_status: bindStatus,
        idcompany,
      };

      return axios.get(server.api_url + '/drivers', config)
        .then((response) => {
          let responseData = response.data.data;

          //remover mascara
          for (let driver of responseData.driverList) {
            if (driver.phone.length > 11) {
              driver.phone = self.removeMaskPhoneDriver(driver.phone);
            }
          }

          
          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    getDriverMixin(id) {
      const self = this;

      return axios.get(server.api_url + '/drivers/' + id, server.config)
        .then((response) => {
          let responseData = response.data.data;
          responseData.driver.status = self.fixStatus(responseData.driver.status);

          //remover mascara
          if (responseData.driver.phone && responseData.driver.phone.length > 11) {
            responseData.driver.phone = self.removeMaskPhoneDriver(responseData.driver.phone);
          }

          if (responseData.driver.company_cnpj && responseData.driver.company_cnpj.length > 14) {
            let cnpj = responseData.driver.company_cnpj.slice(0, 8);
            cnpj += responseData.driver.company_cnpj.slice(-6);
            responseData.driver.company_cnpj = cnpj;
          }

          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    saveDriverMixin(driver, param) {
      const self = this;
      let driverToSend = JSON.parse(JSON.stringify(driver));
      if (driverToSend.zipcode) driverToSend.zipcode = self.removeMaskZipcode(driverToSend.zipcode);
      if (driverToSend.postal_code) driverToSend.postal_code = self.removeMaskZipcode(driverToSend.postal_code);
      if (driverToSend.cpf) driverToSend.cpf = self.removeMaskCPF(driverToSend.cpf);
      if (driverToSend.phone) {
        driverToSend.phone = self.removeMaskPhone(driverToSend.phone);
        driverToSend.phone = self.fixPhoneDriverToSend(driverToSend.phone);
      }
      if (driverToSend.company_cnpj) driverToSend.company_cnpj = self.removeMaskCNPJ(driverToSend.company_cnpj);
      driverToSend.status = self.fixStatus(driverToSend.status);

      this.$refs.validator.validate();
      if (this.$refs.validator.flags.valid) {
        if (param === 'new') {
          this.axios.post(server.api_url + '/drivers/', {
            driver: driverToSend,
          }).then((response) => {
            let responseData = response.data.data;
            self.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: response.data.message,
              // footer: '<a href>Why do I have this issue?</a>'
              showCancelButton: false,
              confirmButtonColor: '#053360',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                window.open('/drivers/' + responseData.driver.iddriver, '_self');
              }
            })
          })
            .catch(function (error) {
              self.showError(error);
              return null;
            });
        } else {
          if (driverToSend.company_name == null) {
            driverToSend.company_name = ""
          }
          if (driverToSend.company_cnpj == null) {
            driverToSend.company_cnpj = ""
          }
          this.axios.put(server.api_url + '/drivers/' + driverToSend.iddriver, {
            driver: driverToSend,
          }).then((response) => {
            self.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: response.data.message,
              // footer: '<a href>Why do I have this issue?</a>'
              showCancelButton: false,
              confirmButtonColor: '#053360',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                location.reload();
              }
            })
          })
            .catch(function (error) {
              self.showError(error);
              return null;
            });
        }
      } else {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Verifique se os campos estão corretos',
          confirmButtonText: 'OK'
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    deleteDriverMixin(id) {
      const self = this;
      this.$swal.fire({
        title: 'Realmente deseja excluir este motorista?',
        text: "Esta ação é irreversível!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#053360',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Excluir'
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(server.url + '/drivers/' + id)
            .then(function (response) {
              let responseData = response.data;
              self.$swal({
                icon: 'success',
                title: 'Sucesso',
                text: responseData.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: '#053360',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  self.$router.push('/drivers/');
                }
              })
            })
            .catch(function (error) {
              self.output = error;
              self.showError(error);
            });
        }
      })
    },
    loginGetSMS(phone) {
      const self = this;
      return axios.post(server.url + '/driver/login/tow-step/', {phone})
        .then(function (response) {
          let responseData = response.data;
          console.log(responseData)
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    loginWithCode(phone, code) {
      const self = this;
      return axios.post(server.url + '/driver/login/tow-step/' + code, {phone})
        .then(function (response) {
          let responseData = response.data;
          console.log(responseData)
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });
    },
    addUserDriverMixin(id, idpark) {
      const self = this;
      if (this.$route.fullPath.indexOf('/companies') == -1) {
        return axios.post(server.url + '/users/' + id + '/park/' + idpark)
          .then(function (response) {
            let responseData = response.data;
            console.log(responseData)
            return responseData;
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      } else {
        return axios.post(server.url + '/companies/' + id + '/park/' + idpark)
          .then(function (response) {
            let responseData = response.data;
            console.log(responseData)

            return responseData;
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      }

    },
    deleteUserDriverMixin(id, idpark) {
      const self = this;
      // console.log('aqui deleteUserDriverMixin', id, idpark)
      if (this.$route.fullPath.indexOf('/companies') == -1) {
        return this.axios.delete(server.url + '/users/' + id + '/park/' + idpark)
          .then(function (response) {
            let responseData = response.data;
            console.log(responseData)
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      } else {
        return this.axios.delete(server.url + '/companies/' + id + '/park/' + idpark)
          .then(function (response) {
            let responseData = response.data;
            console.log(responseData)
          })
          .catch(function (error) {
            self.output = error;
            self.showError(error);
          });
      }
    },
    approveDenyThird(key, action) {
      const self = this;
      const obj = {
        // key: key,
        action: action,
      };

      let config = JSON.parse(JSON.stringify(server.config));
      config["headers"] = {
        Authorization: 'Bearer ' + key,
      };
      console.log(config)

      return axios.post(server.url + '/register', obj, config)
        .then(function (response) {
          let responseData = response.data;
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          self.showError(error);
        });

    },
  }
};

export default driverMixin;