<template>
  <main-layout>
    <div id="driver-delete-account">
      <div class="row">
        <div class="col-md-4 left-part">
          <img src="/static/img/glp-logomark@2x.png" alt="GLP logo" class="logo center">
          <h2>Remover conta</h2>
          <p class="description">Você tem certeza que deseja remover sua conta, está ação é irreversivel!</p>

          <button class="app-btn app-btn-cancel login-btn" id="login-btn">Excluir conta</button>
        </div>
        <div class="col-md-8 no-padding">
          <!-- <img src="/static/img/glp-main.png" alt="GLP img"> -->
          <div class="image-box" style="background-image: url(&quot;./static/img/glp-main.png&quot;);"></div>
        </div>
      </div>
    </div>
    <!-- <alert-message message="Seja bem vindo" type="success"></alert-message> -->

  </main-layout>
</template>

<script>
import driverMixin from "../mixins/driver.mixin.js";
import MainLayout from "../layouts/Main.vue";
// import AlertMessage from '@/components/AlertMessage.vue'
import { getLocalStorage } from "../js/encryption";

export default {
  mixins: [driverMixin],
  components: {
    MainLayout,
    // AlertMessage,
  },
  data() {
    return {
      slideList: [],
      phone: "",
      smsCode: "",
      password: "",
      userForgot: {
        emailToSend: '',
        emailToSendConfirm: '',
      },
      passwordInputType: 'password',
    };
  },
  mounted() {
    localStorage.removeItem('token')
    localStorage.removeItem('user')

    this.isUuid()
  },
  methods: {
    loginUser() {
      const self = this;
      let phoneCopy = JSON.parse(JSON.stringify(this.phone))
      let smsCodeCopy = JSON.parse(JSON.stringify(this.smsCode))
      smsCodeCopy = smsCodeCopy.replace(/\D/g, '')
      if (smsCodeCopy.length !== 6) {
        this.$toasted.error('O código digitado está fora do padrão');
        return
      }
      
      this.loginWithCode(phoneCopy, smsCodeCopy).then((response) => {
        if (response.iduser) {
          self.$toasted.show('Seja bem vindo');
          self.$router.push('/remocao-de-conta-confirmar');
        }
      });
    },
    isUuid() {
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi //regex to verify if is uuid
      const device = getLocalStorage('device')
      if (!device || !regexExp.test(device)) {
        localStorage.removeItem('device')
        window.open("./login", "_self")
      }
    }
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#driver-delete-account {

  .left-part {
    background-color: $lightgray;
    padding: 0px 70px;
    height: 100vh;

    .logo {
      height: 45px;
      margin-top: 90px;
      margin-bottom: 90px;
    }

    h2 {
      text-align: center;
      margin-bottom: 30px;
    }

    .description{
      margin-bottom: 35px;
      font-weight: 500;
      color: $gray-xdk;
      text-align: center;
    }

    .login-btn {
      width: 100%;
      margin-top: 15px;
    }

    .forgot-password-btn {
      float: left;
      margin-top: 15px;
    }
  }

  .image-box {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
}
</style>