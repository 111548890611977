import VueRouter from 'vue-router'

// Import components
// import Home from './pages/Home.vue';
import Page404 from './pages/404.vue';
import Dashboard from './pages/Dashboard.vue'
import Login from './pages/Login.vue'
import LoginSms from './pages/LoginSms.vue'
import DriverDeleteAccount from './pages/DriverDeleteAccount.vue'
import ResetPassword from './pages/ResetPassword.vue'
import DriverApproveThird from './pages/DriverApproveThird.vue'
import ParkList from './pages/ParkList.vue'
import ParkRegister from './pages/ParkRegister.vue'
import UserList from './pages/UserList.vue'
import UserRegister from './pages/UserRegister.vue'
import CompanyList from './pages/CompanyList.vue'
import CompanyRegister from './pages/CompanyRegister.vue'
import DriverList from './pages/DriverList.vue'
import DriverRegister from './pages/DriverRegister.vue'
import RequestList from './pages/RequestList.vue'
import RequestView from './pages/RequestView.vue'
import RequestRegister from './pages/RequestRegister.vue'
import EntryList from './pages/EntryList.vue'
import EntryView from './pages/EntryView.vue'
import ReportList from './pages/ReportList.vue'
import ReportView from './pages/ReportView.vue'
import RegistrationList from './pages/RegistrationList.vue'
import RegistrationView from './pages/RegistrationView.vue'
import VehicleList from './pages/VehicleList.vue'
import VehicleView from './pages/VehicleView.vue'
import CompanionList from './pages/CompanionList.vue'
import CompanionView from './pages/CompanionView.vue'
import ApplicationList from './pages/ApplicationList.vue'
import ApplicationRegister from './pages/ApplicationRegister.vue'
import ThirdCompanyList from './pages/ThirdCompanyList.vue'
import ThirdCompanyRegister from './pages/ThirdCompanyRegister.vue'
import ErrorList from './pages/ErrorList.vue'
import { guard } from '@/middlewares/guard.js'

const routes = [
  { path: '/login', component: Login },
  { path: '/remocao-de-conta', component: LoginSms },
  { path: '/remocao-de-conta-confirmar', component: DriverDeleteAccount }, //TODO colocar guard
  { path: '/reset-password/:id', component: ResetPassword },
  { path: '/approve', component: DriverApproveThird },
  { path: '/dashboard', component: Dashboard, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/parks', component: ParkList, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/parks/:id', component: ParkRegister, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/users', component: UserList, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/users/:id', component: UserRegister, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/companies', component: CompanyList, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/companies/:id', component: CompanyRegister, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/drivers', component: DriverList, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/drivers/:id', component: DriverRegister, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/requests', component: RequestList, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/requests/:id', component: RequestRegister, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/request-view/:id', component: RequestView, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/entries', component: EntryList, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/entries/:id', component: EntryView, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/reports', component: ReportList, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/reports/:id', component: ReportView, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/registrations', component: RegistrationList, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/registrations/:id', component: RegistrationView, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/vehicles', component: VehicleList, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/vehicles/:id', component: VehicleView, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/companions', component: CompanionList, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/companions/:id', component: CompanionView, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/applications', component: ApplicationList, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/applications/:id', component: ApplicationRegister, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/third-companies', component: ThirdCompanyList, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/third-companies/:id', component: ThirdCompanyRegister, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '/integrations', component: ErrorList, beforeEnter: (to, from, next) => { guard(to, from, next); } },
  { path: '*', component: Page404, redirect: '/dashboard' }
];


var path = __dirname;

const router = new VueRouter({
  mode: 'history',
  base: path,
  routes: routes,

  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router;