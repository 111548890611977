import axios from 'axios';
import { server } from '../js/variables.js'
// import moment from 'moment'

var companionMixin = {
  data: function () {
    return {
      companion: {},
    }
  },
  methods: {
    listCompanionsMixin(limit, page, search, order, sort, status, iddriver) {
      const self = this;
      let config = JSON.parse(JSON.stringify(server.config));
      config["params"] = {
        limit: limit,
        page: page,
        search: search,
        order: order,
        sort: sort,
        status: status,
        iddriver: iddriver,
      };

      return axios.get(server.api_url + '/companions', config)
      .then((response) => {
        let responseData = response.data.data;
        
        
        return responseData;
      })
      .catch(function (error) {
        self.showError(error);
        return null;
      });
    },
    getCompanionMixin(id) {
      const self = this;

      return axios.get(server.api_url + '/companions/' + id, server.config)
      .then((response) => {
        let responseData = response.data.data;

        //remover mascara
        for(let driver of responseData.companion.Drivers){
          if(driver.phone && driver.phone.length > 11){
            let phone = driver.phone.slice(4, 6);
            phone += driver.phone.slice(7, 17);
            driver.phone = phone;
          }
        }
        return responseData;
      })
      .catch(function (error) {
        self.showError(error);
        return null;
      });
    },
  }
};

export default companionMixin;