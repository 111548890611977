<template>
  <main-layout>
    <div id="login-sms">
      <div class="row">
        <div class="col-md-4 left-part" v-if="showLogin">
          <img src="/static/img/glp-logomark@2x.png" alt="GLP logo" class="logo center">
          <h2>Remoção de conta</h2>
          <p class="description">Para remover sua conta, informe o número do seu telefone</p>
          <ok-input :model="phone" @updated="(x) => {
            phone = x;
          }
            " :label="'Celular'" :mask="'(##) #####-####'" :placeholder="'(XX) XXXXX-XXXX'" :id="'phone'" :name="'phone'">
          </ok-input>

          <button class="app-btn app-btn-primary login-btn" id="login-btn" @click="getSms()">Receber SMS</button>
        </div>
        <div class="col-md-4 left-part" v-else>
          <img src="/static/img/glp-logomark@2x.png" alt="GLP logo" class="logo center">
          <h2>Validação</h2>
          <p class="description">Digite o número que enviamos para você por SMS, para confirmar a remoção de sua conta</p>
          <ok-input :model="smsCode" @updated="(x) => {
            smsCode = x;
          }
            " :label="'Código'" :mask="'######'" :placeholder="'XXXXXX'" :id="'smsCode'" :name="'smsCode'">
          </ok-input>

          <button class="app-btn app-btn-primary login-btn" id="login-btn" @click="loginUser()">Remover</button>
        </div>
        <div class="col-md-8 no-padding">
          <!-- <img src="/static/img/glp-main.png" alt="GLP img"> -->
          <div class="image-box" style="background-image: url(&quot;./static/img/glp-main.png&quot;);"></div>
        </div>
      </div>
    </div>
    <!-- <alert-message message="Seja bem vindo" type="success"></alert-message> -->

  </main-layout>
</template>

<script>
import driverMixin from "../mixins/driver.mixin.js";
import MainLayout from "../layouts/Main.vue";
import OkInput from '@/components/OkInput.vue'
// import AlertMessage from '@/components/AlertMessage.vue'
import { getLocalStorage } from "../js/encryption";

export default {
  mixins: [driverMixin],
  components: {
    MainLayout,
    OkInput,
    // AlertMessage,
  },
  data() {
    return {
      slideList: [],
      phone: "",
      smsCode: "",
      password: "",
      userForgot: {
        emailToSend: '',
        emailToSendConfirm: '',
      },
      passwordInputType: 'password',
      showLogin: true,
    };
  },
  mounted() {
    localStorage.removeItem('token')
    localStorage.removeItem('user')

    this.isUuid()
  },
  methods: {
    getSms() {
      const self = this;
      let phoneCopy = JSON.parse(JSON.stringify(this.phone))
      phoneCopy = phoneCopy.replace(/\D/g, '')
      if (phoneCopy.length !== 11) {
        this.$toasted.error('Verifique se o número do celular está correto');
        return
      }
      
      this.loginGetSMS(phoneCopy).then((response) => {
        if (response) {
          self.showLogin = false
        }
      });
    },
    loginUser() {
      const self = this;
      let phoneCopy = JSON.parse(JSON.stringify(this.phone))
      phoneCopy = phoneCopy.replace(/\D/g, '')
      let smsCodeCopy = JSON.parse(JSON.stringify(this.smsCode))
      smsCodeCopy = smsCodeCopy.replace(/\D/g, '')
      if (smsCodeCopy.length !== 6) {
        this.$toasted.error('O código digitado está fora do padrão');
        return
      }
      
      this.loginWithCode(phoneCopy, smsCodeCopy).then((response) => {
        if (response) {
          self.$router.push('/remocao-de-conta-confirmar');
        }
      });
    },
    isUuid() {
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi //regex to verify if is uuid
      const device = getLocalStorage('device')
      if (!device || !regexExp.test(device)) {
        localStorage.removeItem('device')
        window.open("./login", "_self")
      }
    }
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#login-sms {

  .left-part {
    background-color: $lightgray;
    padding: 0px 70px;
    height: 100vh;

    .logo {
      height: 45px;
      margin-top: 90px;
      margin-bottom: 90px;
    }

    h2 {
      text-align: center;
      margin-bottom: 30px;
    }

    .description{
      margin-bottom: 35px;
      font-weight: 500;
      color: $gray-xdk;
      text-align: center;
    }

    .login-btn {
      width: 100%;
      margin-top: 15px;
    }

    .forgot-password-btn {
      float: left;
      margin-top: 15px;
    }
  }

  .image-box {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
}
</style>