<template>
  <main-layout>
    <div class="content-container">
      <div id="entry-view" class="content-box">
        <div class="row">
          <div class="col-md-8 separator-bottom">
            <h1 class="title-md">Acesso</h1>
          </div>
          <div class="col-md-4 last">
            <button class="app-btn app-btn-outline left-space separator-bottom-m" @click="goBackWindow()"
              :disabled="$route.params.id != 'new' && entry.identry == undefined">Voltar</button>
          </div>
        </div>
        <div class="row" v-if="entry.Request">
          <div class="col-md-4">
            <div class="data-box" style="padding: 18px 15px;">
              <div class="row">
                <div class="col-md-12 toggle-photo">
                  <ImageItem :path="entry.Request.Driver.image_photo" :isFull="true" v-if="photoType"></ImageItem>
                  <ImageItem :path="entry.Request.Driver.image_document" :isFull="true" v-else></ImageItem>
                </div>
                <div class="col-md-12 last separator-bottom toggle-photo" style="margin-top: 6px;">
                  <button class="left" v-bind:class="{ 'active': photoType }" @click="photoType = true">Foto</button>
                  <button class="right" v-bind:class="{ 'active': !photoType }" @click="photoType = false">CNH</button>
                </div>
                <div class="col-md-12 data-box-left-space">
                  <h2 style="margin-bottom: 10px">{{ entry.Request.Driver.name }} {{ entry.Request.Driver.lastname }}
                  </h2>
                  <p>Telefone: {{ entry.Request.Driver.phone | formatPhone }}</p>
                  <p>E-mail: {{ entry.Request.Driver.email }}</p>
                  <p>CPF: {{ entry.Request.Driver.cpf | formatCpf }}</p>
                  <p>CNH: {{ entry.Request.Driver.cnh }}</p>
                </div>
                <div class="col-md-12 separator">
                  <hr>
                </div>
                <div class="col-md-12 data-box-left-space">
                  <h2 class="highlight" v-if="entry.Request.Driver.company_name">{{ entry.Request.Driver.company_name }}
                  </h2>
                  <p v-else>Empresa: -</p>
                  <p>CNPJ: {{ entry.Request.Driver.company_cnpj | formatCnpj | verifyIfNull }}</p>
                </div>
                <div class="col-md-12 separator">
                  <hr>
                </div>
                <div class="col-md-12 data-box-left-space">
                  <h3 class="highlight">Outras placas</h3>
                  <p>Placa 2: {{ entry.Request.plate2 | verifyIfNull }}</p>
                  <p>Placa 3: {{ entry.Request.plate3 | verifyIfNull }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-12">
                <div class="data-box row">
                  <div class="col-md-6">
                    <p v-if="entry.access_in"><span class="highlight">Entrada: </span><span
                        v-if="gatezoneName == ''">-</span><span v-else>{{ gatezoneName }} <span
                          v-if="gateInName != ''">/</span> {{ gateInName }} </span></p>
                    <p v-else><span class="highlight">Entrada: </span>-</p>
                    <p><span class="highlight">Data: </span>{{ entry.access_in | formatDate }}</p>
                    <p><span class="highlight">Hora: </span>{{ entry.access_in | getHour }}</p>
                  </div>
                  <div class="col-md-6">
                    <p v-if="entry.access_out"><span class="highlight">Saída: </span><span
                        v-if="gatezoneName == ''">-</span><span v-else>{{ gatezoneName }} <span
                          v-if="gateOutName != ''">/</span> {{ gateOutName }} </span></p>
                    <p v-else><span class="highlight">Saída: </span>-</p>
                    <p><span class="highlight">Data: </span>{{ entry.access_out | formatDate }}</p>
                    <p><span class="highlight">Hora: </span>{{ entry.access_out | getHour }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <h2 class="title-sm separator-title">Veículo</h2>
                <vehicle-item :vehicle="entry.Request.Vehicle"></vehicle-item>
              </div>
              <div class="col-md-12" @click="goTo('/users/' + entry.User.iduser)">
                <h2 class="title-sm separator-title">Segurança de entrada</h2>
                <user-item :userItem="entry.User" :simple="true"></user-item>
              </div>
              <div class="col-md-12" @click="goTo('/users/' + entry.User.iduser)">
                <h2 class="title-sm separator-title">Segurança de saída</h2>
                <user-item :userItem="entry.User" :simple="true"></user-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import entryMixin from "../mixins/entry.mixin.js";
import parkMixin from "../mixins/park.mixin.js";
import MainLayout from "../layouts/Template.vue";
import VehicleItem from '@/components/VehicleItem.vue'
import UserItem from '@/components/UserItem.vue'
import ImageItem from '@/components/ImageItem.vue'

export default {
  mixins: [entryMixin, parkMixin],
  components: {
    MainLayout,
    VehicleItem,
    UserItem,
    ImageItem,
  },
  data() {
    return {
      entry: {},
      gazoneList: [],
      gatezoneName: '',
      gateInName: '',
      gateOutName: '',
      tab: "base",
      tab2: "list",
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id !== 'new') {
      this.loadEntry();
    }

  },
  methods: {
    goBackWindow() {
      this.goBack(window)
    },
    loadEntry() {
      let self = this;
      const id = this.$route.params.id;
      this.getEntryMixin(id).then((response) => {
        self.entry = response.entry;
        if (self.entry.Request && self.entry.Request.idpark) self.loadParkGatezones(self.entry.Request.idpark);

      });
    },
    loadParkGatezones(id) {
      let self = this;
      this.getParkGatezonesMixin(id).then((response) => {
        // self.gazoneList = response.gateFree;
        self.gatezoneList = response.gatezoneList;
        if (self.gatezoneList) {
          for (let gatezone of self.gatezoneList) {
            if (gatezone.idgatezone == self.entry.idgatezone) {
              self.gatezoneName = gatezone.name;
            }
            for (let gatehouse of gatezone.gatehouses) {
              for (let gate of gatehouse.gates) {
                if (gate.idgate == self.entry.idgate_in) {
                  self.gateInName = gate.name;
                }
                if (gate.idgate == self.entry.idgate_out) {
                  self.gateOutName = gate.name;
                }
              }
            }
          }
        }
      });
    },
    // getGatezoneName(id){
    //   if(this.gatezoneList){
    //     for(let gatezone of this.gatezoneList){
    //       if(gatezone.idgatezone == id){
    //         return gatezone.name;
    //       }
    //     }
    //   }
    //   return ''
    // },
    // getGateName(id){
    //   if(this.gatezoneList){
    //     for(let gatezone of this.gatezoneList){
    //       for(let gatehouse of gatezone.gatehouses){
    //         for(let gate of gatehouse.gates){
    //           if(gate.idgate == id){
    //             return gate.name;
    //           }
    //         }
    //       }
    //     }
    //   }
    //   return ''
    // },
    changeTab(value) {
      if (value != 'parks') {
        this.tab2 = 'list'
      }
      this.tab = value;
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#entry-view {}
</style>