// const axios = require('axios');
// const { server } = require('../js/variables.js');


const guard = function(to, from, next) {
  // let path = encodeURI(window.location.pathname) ;
  if (localStorage.token) {
    next();
  } else {
    // window.open("./", "_self");
    window.open("./login", "_self");
  }
};
 
  
module.exports = {
  guard
}