import axios from 'axios';
import { server } from '../js/variables.js'
// import moment from 'moment'

var vehicleMixin = {
  data: function () {
    return {
      vehicle: {},
      vehicleTypeList: [
        { name: 'BITREM', value: 'BITREM' },
        { name: 'CARRETA', value: 'CARRETA' },
        { name: 'MOTO', value: 'MOTO' },
        { name: 'ÔNIBUS', value: 'ÔNIBUS' },
        { name: 'TRUCK', value: 'TRUCK' },
        { name: 'TOCO', value: 'TOCO' },
        { name: 'UTILITÁRIO', value: 'UTILITÁRIO' },
        { name: 'VAN', value: 'VAN' },
        { name: 'VUC', value: 'VUC' },
        { name: 'VEÍCULO LEVE', value: 'VEÍCULO LEVE' },
      ],
      vehicleColorList: [
        { name: 'Amarelo', value: '#ffff00' },
        { name: 'Azul', value: '#0000ff' },
        { name: 'Branco', value: '#ffffff' },
        { name: 'Cinza', value: '#555555' },
        { name: 'Prata', value: '#333333' },
        { name: 'Preto', value: '#000000' },
        { name: 'Verde', value: '#00ff00' },
        { name: 'Vermelho', value: '#ff0000' },
        { name: 'Outros', value: '#993399' },
      ],
    }
  },
  methods: {
    listVehiclesMixin(limit, page, search, order, sort, status, iddriver, type, color) {
      const self = this;
      let config = JSON.parse(JSON.stringify(server.config));
      config["params"] = {
        limit: limit,
        page: page,
        search: search,
        order: order,
        sort: sort,
        status: status,
        iddriver: iddriver,
        type: type,
        color: color,
      };

      return axios.get(server.api_url + '/vehicles', config)
        .then((response) => {
          let responseData = response.data.data;


          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    getVehicleMixin(id) {
      const self = this;

      return axios.get(server.api_url + '/vehicles/' + id, server.config)
        .then((response) => {
          let responseData = response.data.data;

          //remover mascara
          for (let driver of responseData.vehicle.Drivers) {
            if (driver.phone.length > 11) {
              let phone = driver.phone.slice(4, 6);
              phone += driver.phone.slice(7, 17);
              driver.phone = phone;
            }
          }
          return responseData;
        })
        .catch(function (error) {
          self.showError(error);
          return null;
        });
    },
    saveVehicleMixin(vehicle, param) {
      const self = this
      let vehicleToSend = JSON.parse(JSON.stringify(vehicle))
      if (param === 'new') {
        this.axios.post(server.api_url + '/vehicles', {
          vehicle: vehicleToSend,
        }).then((response) => {
          let responseData = response.data.data;
          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: response.data.message,
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              window.open('/vehicles/' + responseData.vehicle.idvehicle, '_self')
            }
          })
        })
          .catch(function (error) {
            self.showError(error)
          });
      } else {
        this.axios.put(server.api_url + '/vehicles/' + param, {
          vehicle: vehicleToSend,
        }).then((response) => {
          self.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: response.data.message,
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: false,
            confirmButtonColor: '#053360',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              location.reload();
            }
          })
        })
          .catch(function (error) {
            self.showError(error)
          });
      }

    },
    deleteVehicleMixin(id) {
      const self = this;
      this.$swal.fire({
        title: 'Realmente deseja excluir este veículo?',
        text: "Esta ação é irreversível!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#053360',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Excluir'
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(server.url + '/vehicles/' + id)
            .then(function (response) {
              let responseData = response.data;
              self.$swal({
                icon: 'success',
                title: 'Sucesso',
                text: responseData.message,
                // footer: '<a href>Why do I have this issue?</a>'
                showCancelButton: false,
                confirmButtonColor: '#053360',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  self.$router.push('/vehicles');
                }
              })
            })
            .catch(function (error) {
              self.output = error;
              self.showError(error);
            });
        }
      })
    },
  }
};

export default vehicleMixin;