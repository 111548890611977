<template>
  <main-layout>
    <div class="content-container">
      <div id="companion-view" class="content-box">
        <div class="row">
          <div class="col-md-8 separator-bottom">
            <h1 class="title-md">Acompanhante</h1>
          </div>
          <div class="col-md-4 last">
            <button class="app-btn app-btn-outline left-space" @click="goBackWindow()"
              :disabled="$route.params.id != 'new' && companion.idcompanion == undefined">Voltar</button>
          </div>
          <div class="col-md-12">
            <div class="nav-tab">
              <button class="app-btn left-btn" style="margin-left: 0px;" @click="changeTab('base')"
                v-bind:class="{ 'app-btn-primary ': tab == 'base', 'app-btn-outline ': tab != 'base' }">Dados do
                acompanhante</button>
              <button class="app-btn right-btn" @click="changeTab('drivers')"
                v-bind:class="{ 'app-btn-primary ': tab == 'drivers', 'app-btn-outline ': tab != 'drivers' }">Motoristas</button>
              <button class="app-btn left-btn" @click="changeTab('requests')"
                v-bind:class="{ 'app-btn-primary ': tab == 'requests', 'app-btn-outline ': tab != 'requests' }">Solicitações</button>
              <button class="app-btn right-btn" @click="changeTab('entries')"
                v-bind:class="{ 'app-btn-primary ': tab == 'entries', 'app-btn-outline ': tab != 'entries' }">Acessos</button>
            </div>
          </div>
        </div>
        <div class="row" v-if="tab == 'base'">
          <div class="col-md-3">
            <div class="row">
              <div class="col-md-12">
                <!-- <image-upload v-model="photoNull"></image-upload> -->
                <ImageItem :path="companion.image_photo" :isFull="true" v-if="photoType"></ImageItem>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-6">
                <ok-input :model="companion.name" @updated="(x) => {
                    companion.name = x;
                  }
                  " :label="'Nome'" :placeholder="' '" :id="'name'" :name="'name'" rules="required">
                </ok-input>
              </div>
              <div class="col-md-6">
                <ok-input :model="companion.lastname" @updated="(x) => {
                    companion.lastname = x;
                  }
                  " :label="'Sobrenome'" :placeholder="' '" :id="'lastname'" :name="'lastname'" rules="required">
                </ok-input>
              </div>
              <div class="col-md-6">
                <ok-input :model="companion.email" @updated="(x) => {
                    companion.email = x;
                  }
                  " :label="'E-mail'" :placeholder="' '" :id="'email'" :name="'email'" rules="required|email">
                </ok-input>
              </div>
              <div class="col-md-6">
                <ok-input :model="companion.phone" @updated="(x) => {
                    companion.phone = x;
                  }
                  " :label="'Telefone'" :mask="'(##) #####-####'" :placeholder="' '" :id="'phone'" :name="'phone'">
                </ok-input>
              </div>
              <div class="col-md-6">
                <ok-input :model="companion.birthday" @updated="(x) => {
                    companion.birthday = x;
                  }
                  " :label="'Nascimento'" :placeholder="' '" :id="'birthday'" :name="'birthday'" :mask="'##/##/####'"
                  :isDate="true">
                </ok-input>
              </div>
              <div class="col-md-6">
                <ok-input :model="companion.gender" @updated="(x) => {
                    companion.gender = x;
                  }
                  " :label="'Sexo'" :options="genderOptions" :placeholder="' '" :id="'gender'" :name="'gender'">
                </ok-input>
              </div>
              <div class="col-md-12 last" v-if="false">
                <button class="app-btn app-btn-secondary" v-if="companion.idcompanion" @click="forgotPassword()"
                  style="margin-top: 8px;">Reenviar email de alteração de senha</button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="tab == 'drivers'">
          <div class="col-md-12" v-for="driver in companion.Drivers" :key="driver.iddriver">
            <user-item :userItem="driver" :isDriver="true"></user-item>
          </div>
          <div class="col-md-12">
            <div class="no-content" v-if="companion.Drivers && companion.Drivers.length == 0">
              Este acompanhante não pertence a nenhum motorista.
            </div>
          </div>
        </div>

        <div class="row" v-if="tab == 'requests'">
          <div class="col-md-12" v-for="request in requestList" :key="request.idrequest">
            <driver-request-item :requestItem="request"></driver-request-item>
          </div>
          <div class="col-md-12" style="text-align: center">
            <div class="no-content" v-if="requestList && requestList.length == 0">
              {{ noContentRequestText }}
            </div>
            <button class="app-btn app-btn-load-more"
              v-if="requestList && requestList.length > 0 && filterRequest.canLoadMore"
              @click="listDriverRequests()">Carregar mais</button>
          </div>
        </div>

        <div class="row" v-if="tab == 'entries'">
          <div class="col-md-12" v-for="entry in entryList" :key="entry.identry">
            <driver-entry-item :entryItem="entry"></driver-entry-item>
          </div>
          <div class="col-md-12" style="text-align: center">
            <div class="no-content" v-if="entryList && entryList.length == 0">
              {{ noContentEntryText }}
            </div>
            <button class="app-btn app-btn-load-more" v-if="entryList && entryList.length > 0 && filterEntry.canLoadMore"
              @click="listDriverEntries()">Carregar
              mais</button>
          </div>
        </div>
      </div>
    </div>

  </main-layout>
</template>

<script>
import authMixin from "../mixins/auth.mixin.js";
import companionMixin from "../mixins/companion.mixin.js";
import requestMixin from "../mixins/request.mixin.js";
import entryMixin from "../mixins/entry.mixin.js";
import MainLayout from "../layouts/Template.vue";
import OkInput from '@/components/OkInput.vue'
import UserItem from '@/components/UserItem.vue'
import DriverRequestItem from '@/components/DriverRequestItem.vue'
import DriverEntryItem from '@/components/DriverEntryItem.vue'
import ImageItem from '@/components/ImageItem.vue'
// import ImageUpload from '@/components/ImageUpload.vue'
// import AddressForm from '@/components/AddressForm.vue'

export default {
  mixins: [authMixin, companionMixin, requestMixin, entryMixin],
  components: {
    MainLayout,
    OkInput,
    UserItem,
    DriverRequestItem,
    DriverEntryItem,
    ImageItem,
    // ImageUpload,
    // AddressForm,
  },
  data() {
    return {
      tab: "base",
      parkList: [],
      registrationList: [],
      requestList: [],
      entryList: [],
      noContentRegistrationText: 'Buscando cadastros...',
      noContentRequestText: 'Buscando solicitações...',
      noContentEntryText: 'Buscando acessos...',
      filterDriver: {
        pageItems: 15,
        page: 0,
        canLoadMore: true,
      },
      filterRequest: {
        pageItems: 15,
        page: 0,
        canLoadMore: true,
      },
      filterEntry: {
        pageItems: 15,
        page: 0,
        canLoadMore: true,
      },
    };
  },
  mounted() {
    const id = this.$route.params.id;
    console.log('aqui esta comp', this.companion)
    if (id !== 'new') {
      this.loadCompanion();
      // this.listParks();
    }

  },
  methods: {
    goBackWindow() {
      this.goBack(window)
    },
    loadCompanion() {
      let self = this;
      const id = this.$route.params.id;
      this.getCompanionMixin(id).then((response) => {
        self.companion = response.companion;
      });
    },
    changeTab(value) {
      this.tab = value;
      // console.log('changetab', value)
      if (value == 'requests') this.listCompanionRequests();
      if (value == 'entries') this.listCompanionEntries();
    },
    listCompanionRequests() {
      let self = this;
      const id = this.$route.params.id;
      if (!this.filterRequest.canLoadMore) return
      this.noContentRequestText = 'Buscando solicitações...';

      this.listRequestsCompanionMixin(self.filterRequest.pageItems, self.filterRequest.page, null, null, null, null, null, null, id).then((response) => {
        if (self.filterRequest.page == 0) {
          self.requestList = response.requestList;
        } else {
          self.requestList = self.requestList.concat(response.requestList);
        }
        if (response.requestList.length != self.filterRequest.pageItems) {
          self.filterRequest.canLoadMore = false;
        } else {
          self.filterRequest.canLoadMore = true;
        }
        self.filterRequest.page++

        //remover mascara
        for (let request of self.requestList) {
          if (request.Driver.phone.length > 11) {
            let phone = request.Driver.phone.slice(4, 6);
            phone += request.Driver.phone.slice(7, 17);
            request.Driver.phone = phone;
          }
        }
        self.noContentRequestText = 'Nenhuma solicitação para exibir';
      });
    },
    listCompanionEntries() {
      let self = this;
      const id = this.$route.params.id;
      if (!this.filterEntry.canLoadMore) return
      this.noContentEntryText = 'Buscando acessos...';

      this.listEntriesMixin(self.filterEntry.pageItems, self.filterEntry.page, null, null, null, null, null, null, null, null, id).then((response) => {
        if (self.filterEntry.page == 0) {
          self.entryList = response.entryList;
        } else {
          self.entryList = self.entryList.concat(response.entryList);
        }
        if (response.entryList.length != self.filterEntry.pageItems) {
          self.filterEntry.canLoadMore = false;
        } else {
          self.filterEntry.canLoadMore = true;
        }
        self.filterEntry.page++

        //remover mascara
        for (let request of self.entryList) {
          if (request.Driver.phone.length > 11) {
            let phone = request.Driver.phone.slice(4, 6);
            phone += request.Driver.phone.slice(7, 17);
            request.Driver.phone = phone;
          }
        }
        self.noContentEntryText = 'Nenhum acesso para exibir';
      });
    },
  },
};
</script>

<style scooped lang="scss">
@import "../scss/colors.scss";

#companion-view {}
</style>