<template>
  <div class="data-item request-item">
    <div class="row">
      <div class="col-md-8 data-middle" style="height: 105px;">
        <ImageItem :path="requestItem.Driver.image_photo" :isSmall="false"></ImageItem>
        <div class="middle-infos">
          <p class="highlight">Nome: {{ requestItem.Driver.name }} {{ requestItem.Driver.lastname }}</p>
          <p>Telefone: {{ requestItem.Driver.phone | formatPhone }}</p>
          <p>E-mail: {{ requestItem.Driver.email }}</p>
          <p>Terceiro: {{ requestItem.third?.name | verifyIfNull }}</p>
        </div>
      </div>
      <div class="col-md-4 data-right">
        <div class="date">
          <i class="far fa-calendar" v-if="requestItem.idpackage"></i>
          <p>Data alvo: {{ requestItem.day | formatDate }}</p>
        </div>
        <p>Empresa: {{ requestItem.Company.name | verifyIfNull }}</p>
        <p>Status: <span class="highlight" v-bind:class="userStatusClass(requestItem.status)">{{
          requestStatus(requestItem.status) }}</span></p>
      </div>
    </div>

    <overlay-item :route="route"></overlay-item>
  </div>
</template>

<script>
// import { server, local } from "../js/variables.js";
import userMixin from "../mixins/user.mixin.js";
import OverlayItem from '@/components/OverlayOpenNewTab.vue'
import ImageItem from '@/components/ImageItem.vue'

export default {
  mixins: [userMixin],
  props: ["requestItem"],
  components: {
    OverlayItem,
    ImageItem,
  },
  data() {
    return {
      route: ''
    };
  },
  mounted() {
    this.route = this.requestItem.idpackage ? `/requests/${this.requestItem.idpackage}` : `/request-view/${this.requestItem.idrequest}`
  },
  watch: {
    'requestItem.idrequest': function (newValue, oldValue) {
      if(newValue !== oldValue) this.route = this.requestItem.idpackage ? `/requests/${this.requestItem.idpackage}` : `/request-view/${this.requestItem.idrequest}`
    }
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.request-item {
  .data-right {
    .date {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 15px;

      svg {
        font-size: 12px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .request-item {}
}
</style>